import CoachesData from '@/components/_mixins/CoachesData'

export default {
  head() {
    return {
      ...(this.title
        ? { title: this.title }
        : this.page?.title?.text
        ? { title: this.page.title.text }
        : {}),
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        this.description
          ? {
              hid: 'description',
              name: 'description',
              content: this.description
            }
          : this.page?.subtitle?.text
          ? {
              hid: 'description',
              name: 'description',
              content: this.page.subtitle.text
            }
          : {}
      ]
    }
  },
  mixins: [CoachesData],
  middleware(ctx) {
    ctx.$gtm.push({ event: 'ssr' })
  },
  computed: {
    nextPageUrl() {
      const { next_screen, $config } = this
      if (next_screen?.screen_id) {
        const { query } = this.$route
        const query_obj = {
          ...query,
          ...(this.$getInstance ? { lead_id: this.$getInstance() } : {}),
          ...(this.$store.state.user.initial_funnel.coach_id
            ? { coach_id: this.$store.state.user.initial_funnel.coach_id }
            : {})
        }
        const search = new URLSearchParams(query_obj).toString()

        return `/s/${next_screen.screen_id}?${search}`
      }

      if (this.$route?.query?.cbl) {
        return this.$route.query.cbl
      }

      return this.$getInstance
        ? `${$config.paywall_url}/?lead_id=${this.$getInstance()}`
        : $config.paywall_url
    },
    coach_data() {
      return [
        'offer_info',
        'offer_paywall',
        'offer_payment',
        'paywall-success'
      ].includes(this.page.screen_id)
        ? {
            coach_id: this.$store?.state?.user?.initial_funnel?.coach_id,
            coach_name: this.coach?.name
          }
        : {}
    }
  },
  async mounted() {
    const uid = this.$route?.query?.uid

    if (uid) await this.$ampSetUserId(uid)

    this.$logEvent({
      amp: {
        type: 'Funnel Screen Viewed',
        properties: {
          screen_id: this.page.screen_id,
          ...this.coach_data,
          screen_index: this.page_index,
          funnel_id: this.$store.getters['funnel/version']
        }
      }
    })
  },
  created() {
    this.$gtm.push({ event: 'page_view' })
  },
  methods: {
    async nextStep() {
      this.$logEvent({
        amp: {
          type: 'Funnel Screen Completed',
          properties: {
            screen_id: this.screen_id,
            ...this.coach_data,
            screen_index: this.page_index,
            funnel_id: this.$store.getters['funnel/version']
          }
        }
      })

      await this.$router.push({
        path: this.nextPageUrl,
        query: this.$route.query
      })
    }
  }
}

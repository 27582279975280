import { render, staticRenderFns } from "./index.vue?vue&type=template&id=51a78158&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=51a78158&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51a78158",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Title: function () { return import('/home/runner/work/fjor-wellness-funnel/fjor-wellness-funnel/components/_atoms/Title.vue' /* webpackChunkName: "components/title" */).then(function(m) { return m['default'] || m }) },NuxtDynamic: function () { return import('/home/runner/work/fjor-wellness-funnel/fjor-wellness-funnel/node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(function(m) { return m['default'] || m }) }})


import PageMixin from '~/_mixins/page'
import PopupIsNotIos from '@/components/_ignore_global/PopupIsNotIos'
export default {
  name: 'StartPage',
  components: { PopupIsNotIos },
  mixins: [PageMixin],
  async asyncData(data) {
    const { screens, title, description } = data.store.state.funnel.config
    const current_screen = screens[0]
    const screen_index = screens.indexOf(current_screen)
    const next_screen = screens[screen_index + 1]

    await data.store.dispatch('funnel/setCurrentStep', current_screen)

    return {
      page: current_screen,
      title,
      page_index: 0,
      page_progress: screen_index,
      description,
      next_screen,
      styles: {
        ...(current_screen?.styles ? current_screen.styles : {})
      },
      wrapper_styles: current_screen?.wrapper?.styles || {}
    }
  }
}


export default {
  name: 'PopupIsNotIos',
  data() {
    return {
      success: false,
      show: false,
      email: '',
      loading: false,
      android: false
    }
  },
  mounted() {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const isAndroid = /Android/.test(navigator.userAgent) && !window.MSStream
    if (!isIOS) {
      this.show = true
      isAndroid ? (this.android = true) : ''

      this.$logEvent({
        amp: {
          type: 'Android Popup Viewed',
          properties: {
            platform: navigator.userAgent
          }
        }
      })
    }
  },
  methods: {
    closePopup() {
      this.show = false

      this.$logEvent({
        amp: {
          type: 'Android Popup Closed',
          properties: {
            platform: navigator.userAgent
          }
        }
      })
    },
    async validEmail() {
      this.loading = true
      await this.$nextTick()

      if (this.email) {
        let data = {}
        data.email = this.email
        await this.sendEmail(data)
      }
      this.loading = false
    },
    timeoutClosePopup() {
      if (!this.android) {
        setTimeout(() => {
          this.show = false
        }, 5000)
      }
    },
    async sendEmail(data) {
      const formData = new FormData()
      formData.append('Email', data.email)
      const requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      }

      await fetch(
        'https://script.google.com/macros/s/AKfycbyLnHao8VRRFYWEu_mnWCzA08b6U_OPGmd-dBdv4uC56uA0eARi4WFLFNc30zjYgeZ-/exec',
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          console.log(result)
          this.success = true
          this.timeoutClosePopup()
        })
        .catch(error => console.log('error', error))

      this.$logEvent({
        amp: {
          type: 'Android Popup Email Entered',
          properties: {
            platform: navigator.userAgent,
            email: data.email
          }
        }
      })
    }
  }
}
